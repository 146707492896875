


















































import { computed, defineComponent, reactive, ref } from "@vue/composition-api"

import { useI18n } from "@/_i18n"
import {
  updateProfile,
  UpdateProfileRequest,
  UpdateProfileResult,
} from "@/_service/user"
import { getUser } from "@/_store/user"
import { useApiCall } from "@/_use/apiCall"
import {
  email,
  isEmpty,
  MINIMAL_PASSWORD_LENGTH,
  minLen,
  required,
  requiredIf,
  variousPassword,
} from "@/_validation"

import FormCard from "@/component/FormCard.vue"
import Password from "@/component/Password.vue"

export default defineComponent({
  name: "Profile",
  components: { FormCard, Password },

  setup() {
    // translation
    const { t } = useI18n(
      require.context("./", true, /Profile\.[\w-]+\.(json|ya?ml)$/i)
    )

    const successMsg = ref<string | undefined>(undefined)

    // form data
    const currentEmail = computed(() => getUser()?.emailAddress)
    const profileData = reactive<UpdateProfileRequest>({
      emailAddress: currentEmail.value,
      newPassword: "",
      password: "",
    })

    const onSuccess = () => {
      successMsg.value = t("success") as string
    }

    const {
      isLoading,
      onSubmit,
      errorMessage,
      hasErrors: isErrorVisible,
    } = useApiCall<UpdateProfileRequest, UpdateProfileResult>(
      updateProfile,
      t,
      profileData,
      onSuccess
    )

    //validation
    const isNewPasswordEmpty = computed(() => isEmpty(profileData.newPassword))
    const isEmailAddressEmpty = computed(() =>
      isEmpty(profileData.emailAddress)
    )

    const validationRules = {
      emailAddress: {
        ruleDefs: [
          [requiredIf(isNewPasswordEmpty), "error.emailAddress.requiredIf"],
          [email, "error.emailAddress.invalid"],
        ],
        deps: isNewPasswordEmpty,
      },
      newPassword: {
        ruleDefs: [
          [requiredIf(isEmailAddressEmpty), "error.newPassword.requiredIf"],
          [variousPassword, "error.newPassword.tooWeak"],
          [
            minLen(MINIMAL_PASSWORD_LENGTH),
            ["error.newPassword.minLen", { minLen: MINIMAL_PASSWORD_LENGTH }],
          ],
        ],
        deps: isEmailAddressEmpty,
      },
      password: [[required, "error.password.required"]],
    }

    return {
      currentEmail,
      errorMessage,
      isErrorVisible,
      isLoading,
      onSubmit: () => {
        successMsg.value = undefined
        onSubmit()
      },
      successMsg,
      profileData,
      t,
      validationRules,
    }
  },
})
